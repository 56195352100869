import {Color, NumberWithUnit} from '@webaker/package-utils';

export type CSSThemeSemanticColor =
    'primary' |
    'secondary' |
    'info' |
    'success' |
    'warning' |
    'danger' |
    'muted' |
    'marked';

export type CSSThemeGrayColor =
    'black' |
    'veryDark' |
    'dark' |
    'gray' |
    'light' |
    'veryLight' |
    'white';

export type CSSThemeHueColor =
    'red' |
    'rose' |
    'pink' |
    'magenta' |
    'purple' |
    'indigo' |
    'blue' |
    'azure' |
    'cyan' |
    'teal' |
    'green' |
    'lime' |
    'yellow' |
    'amber' |
    'orange' |
    'scarlet';

export type CSSThemeColor =
    CSSThemeSemanticColor |
    CSSThemeGrayColor |
    CSSThemeHueColor;

export type CSSThemeTime =
    'none' |
    'veryShort' |
    'short' |
    'medium' |
    'long' |
    'veryLong';

export type CSSThemeSize =
    'none' |
    'verySmall' |
    'small' |
    'medium' |
    'large' |
    'veryLarge';

export type CSSThemeDepth =
    'veryBack' |
    'back' |
    'middle' |
    'front' |
    'veryFront';

export type CSSThemeMediaQuery =
    'phone' |
    'tablet' |
    'desktop';

export interface CSSTheme {
    id: string;
    name: string;
    color: Record<CSSThemeColor, Color>;
    spacing: Record<CSSThemeSize, NumberWithUnit<'px' | 'em'>>;
    fontSize: Record<CSSThemeSize, NumberWithUnit<'px' | 'em'>>;
    borderRadius: Record<CSSThemeSize, NumberWithUnit<'px' | 'em'>>;
    transition: Record<CSSThemeTime, NumberWithUnit<'s' | 'ms'>>;
    zIndex: Record<CSSThemeDepth, number>;
    boxShadow: Record<CSSThemeSize, string>;
    textShadow: Record<CSSThemeSize, string>;
    mediaQuery: Record<CSSThemeMediaQuery, string>;
}