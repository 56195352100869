import {css} from '@webaker/package-css';
import {CSSThemeColor, CSSThemeProps, CSSThemeSize} from '@webaker/package-css-theme';
import {adjustColor} from '@webaker/package-utils';

export type AlertClassName = 'alert' | 'icon' | 'message';

export function AlertCSS({theme}: CSSThemeProps) {
    return css<AlertClassName>`

        .alert {
            position: relative;
            padding: ${theme.spacing.medium};
            border-radius: ${theme.borderRadius.medium};
            display: flex;
            align-items: center;
            gap: ${theme.spacing.medium};
            background: radial-gradient(circle, var(--color-alpha-2), var(--color-alpha-1));
            border: 1px solid var(--color-alpha-1);
            color: var(--color);
            margin: var(--margin) 0;

            a {
                font-weight: bold;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }

            * {
                color: inherit;
            }
        }

        .icon {
            font-size: ${theme.fontSize.large};
            margin: 0 ${theme.spacing.small};
            display: flex;
            flex-direction: column;
            gap: ${theme.spacing.medium};
        }

        .message {
            flex: 1;
        }

    `;
}

export interface AlertPrivateCSSProps extends CSSThemeProps {
    color: CSSThemeColor;
    margin: CSSThemeSize;
}

export function AlertPrivateCSS({theme, color, margin}: AlertPrivateCSSProps) {
    return css<AlertClassName>`

        .alert {
            --color: ${theme.color[color]};
            --color-alpha-1: ${adjustColor(theme.color[color], {alpha: 0.1})};
            --color-alpha-2: ${adjustColor(theme.color[color], {alpha: 0.2})};
            --margin: ${theme.spacing[margin]};
        }

    `;
}