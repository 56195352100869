import {Api} from '@webaker/package-api';
import {Permission} from './permission';

export const PERMISSION_API_NAME = 'permission';

export interface PermissionApi extends Api {
    name: typeof PERMISSION_API_NAME;
    getPermissionByType: (type: Permission['type']) => Promise<Permission | null>;
    getPermissionByTypeAndEntityId: (type: Permission['type'], entityId: NonNullable<Permission['entityId']>) => Promise<Permission | null>;
    savePermission: (permission: Permission) => Promise<void>;
    deletePermission: (id: Permission['id']) => Promise<void>;
}