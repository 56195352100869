import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {createContext, ReactNode, useCallback, useContext, useMemo, useState} from 'react';
import {Button} from './button';
import {MdIcon} from './md-icon';
import {ToastCSS} from './toast-css';

export interface Toast {
    id: number;
    message: ReactNode;
}

type AddToastFunction = (message: ReactNode, options?: ToastOptions) => void;
type RemoveToastFunction = (toastId: Toast['id']) => void;

export interface ToastOptions {
    timeout?: number;
    replace?: boolean;
}

export interface ToastsContext {
    toasts: Toast[];
    addToast: AddToastFunction;
    removeToast: RemoveToastFunction;
}

export const ToastsContext = createContext<ToastsContext>(null!);

export interface ToastsContextProviderProps {
    children: ReactNode;
    timeout?: number;
}

let toastId = 0;

export function ToastsContextProvider({children, timeout: defaultTimeout = 3000}: ToastsContextProviderProps) {

    const [toasts, setToasts] = useState<Toast[]>([]);

    const removeToast = useCallback((toastId: Toast['id']) => {
        setToasts((toasts: Toast[]): Toast[] => {
            return toasts.filter((currentToast: Toast): boolean => {
                return currentToast.id !== toastId;
            });
        });
    }, []);

    const addToast = useCallback((message: ReactNode, {timeout = defaultTimeout, replace}: ToastOptions = {}) => {
        setToasts((toasts: Toast[]): Toast[] => {
            if (replace) {
                const lastToast = toasts[toasts.length - 1];
                if (lastToast?.message === message) {
                    toasts = toasts.slice(0, -1);
                }
            }
            const id = toastId++;
            if (timeout) {
                setTimeout(() => {
                    removeToast(id);
                }, timeout);
            }
            return [...toasts, {id, message}];
        });
    }, [removeToast]);

    const context = useMemo(() => {
        return {toasts, addToast, removeToast};
    }, [toasts, addToast, removeToast]);

    return (
        <ToastsContext.Provider value={context}>
            {children}
        </ToastsContext.Provider>
    );

}

export interface ToastsProps {
    className?: string;
}

export function Toasts({className}: ToastsProps) {

    const css = useThemedCSS(ToastCSS);
    const {toasts, removeToast} = useContext(ToastsContext);

    return (
        <div className={css['wrapper']}>
            {toasts.map((toast: Toast) => (
                <div key={toast.id}
                     className={mergeClassNames(css['toast'], className)}>
                    <div className={css['message']}>
                        {toast.message}
                    </div>
                    <Button color="white"
                            icon={<MdIcon name="close"/>}
                            onClick={() => removeToast(toast.id)}
                            className={css['button']}/>
                </div>
            ))}
        </div>
    );

}

export function useToast(): AddToastFunction {
    return useContext(ToastsContext).addToast;
}