import {PageRegistry} from '@webaker/app';
import {ApiClientFactory} from '@webaker/package-api/client';
import {Provider} from '@webaker/package-deps';
import {UserRole} from '@webaker/package-user';
import {UserManagerPageApi} from '../user-manager-page-api';
import {createUserManagerPageOptions} from '../user-manager-page-options';
import {createUserManagerPageClientApi} from './user-manager-page-client-api';

export interface ProvidedUserManagerPageClientDeps {
    userManagerPageApi: UserManagerPageApi;
}

export interface RequiredUserManagerPageClientDeps {
    apiClientFactory: ApiClientFactory;
    pageRegistry: PageRegistry;
}

export interface UserManagerPageClientDeps extends ProvidedUserManagerPageClientDeps, RequiredUserManagerPageClientDeps {

}

export interface UserManagerPageClientConfig {
    adminRoleId: UserRole['id'];
}

export type UserManagerPageClientProvider = Provider<UserManagerPageClientDeps, UserManagerPageClientConfig>;

export function createUserManagerPageClientProvider(): UserManagerPageClientProvider {
    return {

        registerDependencies: async ({register}) => {
            register('userManagerPageApi', ({resolve}) => {
                return createUserManagerPageClientApi({
                    apiClientFactory: resolve('apiClientFactory')
                });
            });
        },

        registerServices: async ({resolve}) => {
            const pageRegistry = resolve('pageRegistry');
            const loginPageOptions = createUserManagerPageOptions();
            pageRegistry.registerPage(loginPageOptions);
        }

    };
}