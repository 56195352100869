import {IdGenerator, omitUndefined} from '@webaker/package-utils';
import {PartialPermission, Permission} from './permission';

export interface PermissionFactory {
    createPermission: (data: PartialPermission) => Permission;
}

export interface PermissionFactoryDeps {
    idGenerator: IdGenerator;
}

export function createPermissionFactory({idGenerator}: PermissionFactoryDeps): PermissionFactory {

    const createPermission = (data: PartialPermission): Permission => {
        return omitUndefined({
            id: data.id ?? idGenerator.generateId(),
            type: data.type,
            entityId: data.entityId ?? undefined,
            rolesIds: data.rolesIds ?? []
        });
    };

    return {
        createPermission
    };

}