import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type CardButtonsClassName = 'cardButtons';

export interface CardButtonsCSSProps extends CSSThemeProps {

}

export function CardButtonsCSS({theme}: CardButtonsCSSProps) {
    return css<CardButtonsClassName>`

        .cardButtons {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            gap: ${theme.spacing.medium};
            margin: ${theme.spacing.large} 0 0 auto;
        }

    `;
}