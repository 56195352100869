import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type NumberFieldClassName = 'numberField' | 'unitButton' | 'sliderButton';

export interface NumberFieldCSSProps extends CSSThemeProps {

}

export function NumberFieldCSS({theme}: NumberFieldCSSProps) {
    return css<NumberFieldClassName>`

        .numberField {
            position: relative;
            height: 2.25em;
        }
        
        .unitButton {
            margin: ${theme.spacing.small} 0;
            border-radius: ${theme.borderRadius.small};
            background-color: ${theme.color.veryLight};
        }
        
        .sliderButton {
            height: auto !important;
        }

    `;
}