import {CSSTheme} from '@webaker/package-css-theme';

export interface CSSThemeConfig {
    defaultCSSTheme: Omit<CSSTheme, 'id'>;
}

export function getCSSThemeConfig(): CSSThemeConfig {

    const black = /*    */ '#000000';
    const veryDark = /* */ '#2b2e3a';
    const dark = /*     */ '#464959';
    const gray = /*     */ '#878ba1';
    const light = /*    */ '#bdc1da';
    const veryLight = /**/ '#e2e5f3';
    const white = /*    */ '#ffffff';

    const red = /*      */ '#ec3120';
    const rose = /*     */ '#dc2a61';
    const pink = /*     */ '#dc3fa9';
    const magenta = /*  */ '#b35fd5';
    const purple = /*   */ '#8865dc';
    const indigo = /*   */ '#6767de';
    const blue = /*     */ '#4a75cb';
    const azure = /*    */ '#3a8bdc';
    const cyan = /*     */ '#3caada';
    const teal = /*     */ '#42b78c';
    const green = /*    */ '#74c963';
    const lime = /*     */ '#a9dc54';
    const yellow = /*   */ '#ecec55';
    const amber = /*    */ '#ecb149';
    const orange = /*   */ '#e17c49';
    const scarlet = /*  */ '#e14533';

    return {
        defaultCSSTheme: {

            name: 'Default',

            color: {
                primary: /*   */ amber,
                secondary: /* */ scarlet,
                info: /*      */ blue,
                success: /*   */ teal,
                warning: /*   */ amber,
                danger: /*    */ rose,
                muted: /*     */ gray,
                marked: /*    */ rose,
                black,
                veryDark,
                dark,
                gray,
                light,
                veryLight,
                white,
                red,
                rose,
                pink,
                magenta,
                purple,
                indigo,
                blue,
                azure,
                cyan,
                teal,
                green,
                lime,
                yellow,
                amber,
                orange,
                scarlet
            },

            spacing: {
                none: /*      */ '0',
                verySmall: /* */ '0.2em',
                small: /*     */ '0.4em',
                medium: /*    */ '0.6em',
                large: /*     */ '1em',
                veryLarge: /* */ '2em'
            },

            fontSize: {
                none: /*      */ '0',
                verySmall: /* */ '0.75em',
                small: /*     */ '0.875em',
                medium: /*    */ '1em',
                large: /*     */ '1.25em',
                veryLarge: /* */ '1.75em'
            },

            borderRadius: {
                none: /*      */ '0',
                verySmall: /* */ '2px',
                small: /*     */ '4px',
                medium: /*    */ '8px',
                large: /*     */ '16px',
                veryLarge: /* */ '32px'
            },

            transition: {
                none: /*      */ '0ms',
                veryShort: /* */ '10ms',
                short: /*     */ '100ms',
                medium: /*    */ '200ms',
                long: /*      */ '1000ms',
                veryLong: /*  */ '2000ms'
            },

            zIndex: {
                veryBack: /*  */ 1,
                back: /*      */ 2,
                middle: /*    */ 3,
                front: /*     */ 4,
                veryFront: /* */ 5
            },

            boxShadow: {
                none: /*      */ 'none',
                verySmall: /* */ '#0001 0 0 0 1px, #0001 0 2px 4px 0',
                small: /*     */ '#0001 0 0 0 1px, #0001 0 4px 8px 0',
                medium: /*    */ '#0001 0 0 0 1px, #0001 0 8px 16px 0',
                large: /*     */ '#0002 0 0 0 1px, #0002 0 16px 32px 0',
                veryLarge: /* */ '#0002 0 0 0 1px, #0002 0 32px 64px 0'
            },

            textShadow: {
                none: /*      */ 'none',
                verySmall: /* */ '#0001 0 1px 2px',
                small: /*     */ '#0001 0 2px 4px',
                medium: /*    */ '#0001 0 4px 8px',
                large: /*     */ '#0002 0 6px 12px',
                veryLarge: /* */ '#0002 0 8px 16px'
            },

            mediaQuery: {
                phone: /*     */ '(max-width: 767px)',
                tablet: /*    */ '(min-width: 768px) and (max-width: 1023px)',
                desktop: /*   */ '(min-width: 1024px)',
            }

        }
    };

}