import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';
import {color, contrast} from '@webaker/package-utils';

export type AutocompleteClassName = 'autocomplete' | 'option' | 'icon' | 'search' | 'is-active' | 'is-highlighted';

export interface AutocompleteCSSProps extends CSSThemeProps {

}

export function AutocompleteCSS({theme}: AutocompleteCSSProps) {
    return css<AutocompleteClassName>`

        .autocomplete {
            background-color: ${theme.color.white};
            border-radius: ${theme.borderRadius.medium};
            overflow-x: hidden;
            overflow-y: auto;
            z-index: ${theme.zIndex.front};
            max-height: 200px;
            box-shadow: ${theme.boxShadow.medium};
            min-width: 100%;

            &::-webkit-scrollbar {
                width: 8px;
            }

            &::-webkit-scrollbar-track {
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background-color: ${color(theme.color.black, {alpha: 0.2})};
                border-radius: 2px;

                &:hover {
                    background-color: ${color(theme.color.black, {alpha: 0.3})};
                }
            }
        }

        .search {
            padding: ${theme.spacing.medium};
            background-color: ${theme.color.white};
            color: ${contrast(theme.color.white)};
            border: none;
            display: block;
            width: 100%;
            outline: none;
            color: ${theme.color.gray};
            height: 2.25em;
        }

        .option {
            padding: ${theme.spacing.medium};
            background-color: ${theme.color.white};
            color: ${contrast(theme.color.white)};
            cursor: pointer;
            min-height: 2.25em;
            display: flex;
            align-items: center;
            gap: ${theme.spacing.medium};

            &.is-active {
                background-color: ${theme.color.info};
                color: ${contrast(theme.color.info)};
            }

            &.is-highlighted:not(.is-active) {
                background-color: ${color(theme.color.info, {alpha: 0.2})};
            }
        }

        .icon {

        }

    `;
}