import {css} from '@webaker/package-css';
import {CSSThemeColor, CSSThemeProps} from '@webaker/package-css-theme';
import {adjustColor, contrast} from '@webaker/package-utils';
import {ButtonStyle} from './button';

export type ButtonClassName = 'button' | 'icon' | 'label' | 'is-active' | `style-${ButtonStyle}`;

export function ButtonCSS({theme}: CSSThemeProps) {

    return css<ButtonClassName>`

        .button {
            position: relative;
            border-radius: ${theme.borderRadius.medium};
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: ${theme.spacing.medium};
            padding: ${theme.spacing.medium};
            font-weight: bold;
            height: 2.25em;
            transition: background-color ${theme.transition.short}, box-shadow ${theme.transition.short}, filter ${theme.transition.short};
            border: none;
            outline: none;
        }

        .button:has(.label) {
            padding: ${theme.spacing.medium} ${theme.spacing.large};
        }

        .button:disabled {
            cursor: default;

            .icon, .label {
                opacity: 0.5;
            }
        }

        .icon {
            vertical-align: middle;
            font-size: ${theme.fontSize.large};
        }

        .label {
            white-space: nowrap;
        }

        .style-solid {
            background: var(--color);
            color: var(--color-contrast);
            box-shadow: ${theme.boxShadow.verySmall};
            border: 1px solid ${adjustColor(theme.color.black, {alpha: 0.1})};

            &:hover:not(:disabled):not(:active, .is-active) {
                filter: brightness(1.1);
            }

            &:is(:active, .is-active, :focus-visible):not(:disabled) {
                filter: brightness(1.2);
            }
        }

        .style-input {
            background-color: ${theme.color.white};
            color: var(--color);
            box-shadow: ${theme.boxShadow.verySmall};

            &:disabled {
                background-color: ${theme.color.veryLight};
                box-shadow: ${theme.boxShadow.verySmall} inset;
            }

            &:is(:hover, :active, .is-active, :focus-visible):not(:disabled) {
                outline: 1px solid var(--color);
            }
        }

        .style-text {
            background-color: transparent;
            color: var(--color);

            &:hover:not(:disabled, :active, .is-active) {
                background: var(--color-alpha-1);
            }

            &:is(:active, .is-active, :focus-visible):not(:disabled) {
                background: var(--color-alpha-2);
            }
        }

        .style-outline {
            background-color: transparent;
            color: var(--color);
            border: 1px solid var(--color);

            &:hover:not(:disabled, :active, .is-active) {
                background-color: var(--color-alpha-1);
            }

            &:is(:active, .is-active, :focus-visible):not(:disabled) {
                background-color: var(--color-alpha-2);
            }
        }

    `;
}

export interface ButtonPrivateCSSProps extends CSSThemeProps {
    color: CSSThemeColor;
}

export function ButtonPrivateCSS({theme, color}: ButtonPrivateCSSProps) {

    return css<ButtonClassName>`

        .button {
            --color: ${theme.color[color]};
            --color-contrast: ${contrast(theme.color[color])};
            --color-alpha-1: ${adjustColor(theme.color[color], {alpha: 0.1})};
            --color-alpha-2: ${adjustColor(theme.color[color], {alpha: 0.2})};
        }

    `;
}