import {GetterParams, SetterParams, Store, StoreFactory} from '@webaker/package-store';
import {SettingsMap} from './setting';

export const SETTINGS_STORE_NAME = 'settings';

export interface SettingsStoreState {
    settingsMap: SettingsMap;
    localSettingsMap: SettingsMap;
    hasChanges: boolean;
}

export interface SettingsStore extends Store<SettingsStoreState> {
    name: typeof SETTINGS_STORE_NAME;
    getValue: <M extends {} = any, K extends keyof M = keyof M>(key: K) => M[K] | null;
    getLocalValue: <M extends {} = any, K extends keyof M = keyof M>(key: K) => M[K] | null;
    getMap: <M extends {} = any>() => Partial<M>;
    setValue: <M extends {} = any, K extends keyof M = keyof M>(key: K, value: M[K] | null) => void;
    setLocalValue: <M extends {} = any, K extends keyof M = keyof M>(key: K, value: M[K] | null) => void;
    setMap: (map: SettingsMap) => void;
    hasChanges: () => boolean;
    markChanges: () => void;
    clearChanges: () => void;
}

export interface SettingsStoreDeps {
    storeFactory: StoreFactory;
}

export function createSettingsStore({storeFactory}: SettingsStoreDeps): SettingsStore {

    return storeFactory.createStore<SettingsStore>({

        name: SETTINGS_STORE_NAME,

        state: {
            settingsMap: {},
            localSettingsMap: {},
            hasChanges: false
        },

        getters: {

            getValue: <M extends {} = any, K extends keyof M = keyof M>({state}: GetterParams<SettingsStoreState>, key: K): M[K] | null => {
                return state.settingsMap[key] ?? null;
            },

            getLocalValue: <M extends {} = any, K extends keyof M = keyof M>({state}: GetterParams<SettingsStoreState>, key: K): M[K] | null => {
                return state.localSettingsMap[key] ?? null;
            },

            getMap: <M extends {} = any>({state}: GetterParams<SettingsStoreState>): Partial<M> => {
                return state.settingsMap as Partial<M>;
            },

            hasChanges: ({state}): boolean => {
                return state.hasChanges;
            }

        },

        setters: {

            setValue: <M extends {} = any, K extends keyof M = keyof M>({state}: SetterParams<SettingsStoreState>, key: K,
                value: M[K] | null): SettingsStoreState => {
                return {
                    ...state,
                    settingsMap: {
                        ...state.settingsMap,
                        [key]: value
                    }
                };
            },

            setLocalValue: <M extends {} = any, K extends keyof M = keyof M>({state}: SetterParams<SettingsStoreState>, key: K,
                value: M[K]): SettingsStoreState => {
                return {
                    ...state,
                    localSettingsMap: {
                        ...state.localSettingsMap,
                        [key]: value
                    }
                };
            },

            setMap: ({state}, map: SettingsMap): SettingsStoreState => {
                return {
                    ...state,
                    settingsMap: map
                };
            },

            markChanges: ({state}): SettingsStoreState => {
                return {
                    ...state,
                    hasChanges: true
                };
            },
            clearChanges: ({state}): SettingsStoreState => {
                return {
                    ...state,
                    hasChanges: false
                };
            }

        }

    });

}