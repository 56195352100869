import {Extension} from '../extension';
import {ExtensionAlreadyRegisteredError} from '../extension-already-registered-error';
import {ExtensionChangeEvent} from '../extension-events';
import {ExtensionChangeListener} from '../extension-listeners';
import {ExtensionManager} from '../extension-manager';
import {ExtensionNotFoundError} from '../extension-not-found-error';
import {EXTENSIONS_ELEMENT_ID} from '../extensions-injector';

export interface ExtensionClientManager<E extends Extension = Extension> extends ExtensionManager<E> {

}

export interface ExtensionClientManagerDeps {

}

export async function createExtensionClientManager<E extends Extension = Extension>({}: ExtensionClientManagerDeps = {}): Promise<ExtensionClientManager<E>> {

    const extensionsMap: Map<E['name'], E> = new Map();
    const changeListenersSet: Set<ExtensionChangeListener<E>> = new Set();
    const enabledExtensionsNamesSet: Set<E['name']> = new Set();

    const registerExtension = (extension: E): void => {
        if (extensionsMap.has(extension.name)) {
            throw new ExtensionAlreadyRegisteredError(`Extension [${extension.name}] has already been registered`, {
                name: extension.name
            });
        }
        extensionsMap.set(extension.name, extension);
    };

    const registerExtensions = (extensions: E[]): void => {
        extensions.forEach(registerExtension);
    };

    const getExtensionByName = (name: E['name']): E | null => {
        return extensionsMap.get(name) || null;
    };

    const getAllExtensions = (): E[] => {
        return Array.from(extensionsMap.values());
    };

    const getAllExtensionsNames = (): E['name'][] => {
        return Array.from(extensionsMap.keys());
    };

    const getEnabledExtensions = (): E[] => {
        return getAllExtensions().filter((extension: E): boolean => {
            return enabledExtensionsNamesSet.has(extension.name);
        });
    };

    const getEnabledExtensionsNames = (): Extension['name'][] => {
        return getAllExtensionsNames().filter((name: Extension['name']): boolean => {
            return enabledExtensionsNamesSet.has(name);
        });
    };

    const enableExtension = async (name: E['name']): Promise<void> => {
        if (!extensionsMap.has(name)) {
            throw new ExtensionNotFoundError(`Extension [${name}] not found`, {name});
        }
        const shouldChange = !enabledExtensionsNamesSet.has(name);
        if (shouldChange) {
            enabledExtensionsNamesSet.add(name);
            runChangeListeners({name, isEnabled: true});
        }
    };

    const disableExtension = async (name: E['name']): Promise<void> => {
        if (!extensionsMap.has(name)) {
            throw new ExtensionNotFoundError(`Extension [${name}] not found`, {name});
        }
        const shouldChange = enabledExtensionsNamesSet.has(name);
        if (shouldChange) {
            enabledExtensionsNamesSet.delete(name);
            runChangeListeners({name, isEnabled: false});
        }
    };

    const addChangeListener = (listener: ExtensionChangeListener<E>): void => {
        changeListenersSet.add(listener);
    };

    const removeChangeListener = (listener: ExtensionChangeListener<E>): void => {
        changeListenersSet.delete(listener);
    };

    const runChangeListeners = (event: ExtensionChangeEvent<E>): void => {
        changeListenersSet.forEach((onExtensionsChangeListener: ExtensionChangeListener<E>): void => {
            onExtensionsChangeListener(event);
        });
    };

    const initEnabledExtensions = async (): Promise<void> => {
        const extensionsScript = document.getElementById(EXTENSIONS_ELEMENT_ID);
        if (extensionsScript) {
            const enabledExtensions = JSON.parse(extensionsScript.innerText.trim());
            if (Array.isArray(enabledExtensions)) {
                enabledExtensions.forEach((name: E['name']): void => {
                    enabledExtensionsNamesSet.add(name);
                });
            }
        }
    };

    await initEnabledExtensions();

    return {
        registerExtension,
        registerExtensions,
        getExtensionByName,
        getAllExtensions,
        getAllExtensionsNames,
        getEnabledExtensions,
        getEnabledExtensionsNames,
        enableExtension,
        disableExtension,
        addChangeListener,
        removeChangeListener
    };

}