import {User} from './user';
import {UserRole} from './user-role';

export interface UserHelper {
    hasUserRole: (user: User, roleId: UserRole['id']) => boolean;
    isAdmin: (user: User) => boolean;
}

export interface UserHelperDeps {

}

export interface UserHelperConfig {
    adminRoleId: UserRole['id'];
}

export function createUserHelper({}: UserHelperDeps, {adminRoleId}: UserHelperConfig): UserHelper {

    const hasUserRole = (user: User, roleId: UserRole['id']): boolean => {
        return user.rolesIds.includes(roleId);
    };

    const isAdmin = (user: User): boolean => {
        return hasUserRole(user, adminRoleId);
    };

    return {
        hasUserRole,
        isAdmin
    };

}