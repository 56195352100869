import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {ReactNode} from 'react';
import {CardButtonsCSS} from './card-buttons-css';

export interface CardButtonsProps {
    children?: ReactNode;
    className?: string;
}

export function CardButtons({children, className}: CardButtonsProps) {

    const css = useThemedCSS(CardButtonsCSS);

    return (
        <div className={mergeClassNames(css['cardButtons'], className)}>
            {children}
        </div>
    );

}