import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type SliderButtonClassName = 'sliderButton' | 'dragHandleIcon';

export interface SliderButtonCSSProps extends CSSThemeProps {

}

export function SliderButtonCSS({theme}: SliderButtonCSSProps) {
    return css<SliderButtonClassName>`

        .sliderButton {
            background: none;
            border: none;
            border-radius: ${theme.borderRadius.medium};
            color: ${theme.color.dark};
            cursor: ew-resize;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: ${theme.spacing.medium};
            padding: ${theme.spacing.medium};
            font-weight: bold;
            height: 2.25em;
            transition: background-color ${theme.transition.short}, filter ${theme.transition.short};

            &:disabled {
                color: ${theme.color.gray};
                cursor: default;
            }
        }

        .dragHandleIcon {
            transform: rotateZ(90deg);
        }

    `;
}