import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames, useSafeHotkey} from '@webaker/package-utils';
import {ReactNode, useEffect} from 'react';
import {ModalCSS} from './modal-css';
import {Overlay} from './overlay';

export interface ModalProps {
    title?: string;
    width?: string;
    children?: ReactNode;
    onEscape?: () => void;
    onEnter?: () => void;
    className?: string;
}

export function Modal({title, width, children, onEscape, onEnter, className}: ModalProps) {

    const css = useThemedCSS(ModalCSS);

    useEffect(() => {
        (document.activeElement as HTMLElement)?.blur();
    }, []);

    useSafeHotkey('Escape', () => {
        onEscape?.();
    }, [onEscape]);

    useSafeHotkey('Enter', () => {
        onEnter?.();
    }, [onEnter]);

    return (
        <Overlay>
            <div className={mergeClassNames(css['modal'], className)}
                 style={{width}}>
                {title && (
                    <header className={css['title']}>
                        {title}
                    </header>
                )}
                {children}
            </div>
        </Overlay>
    );

}