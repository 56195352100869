import {ApiClientFactory} from '@webaker/package-api/client';
import {Provider} from '@webaker/package-deps';
import {EventBus} from '@webaker/package-event-bus';
import {StoreFactory, StoreRegistry} from '@webaker/package-store';
import {IdGenerator} from '@webaker/package-utils';
import {Validator} from '@webaker/package-validation';
import {UserApi} from '../user-api';
import {createUserFactory, UserFactory} from '../user-factory';
import {createUserHelper, UserHelper} from '../user-helper';
import {createUserManager, UserManager} from '../user-manager';
import {UserRole} from '../user-role';
import {createUserRoleFactory, UserRoleFactory} from '../user-role-factory';
import {createUserRoleValidator, UserRoleValidator} from '../user-role-validator';
import {createUserStore, UserStore} from '../user-store';
import {createUserValidator, UserValidator} from '../user-validator';
import {createUserClientApi} from './user-client-api';

export interface ProvidedUserClientDeps {
    userApi: UserApi;
    userFactory: UserFactory;
    userHelper: UserHelper;
    userManager: UserManager;
    userRoleFactory: UserRoleFactory;
    userRoleValidator: UserRoleValidator;
    userStore: UserStore;
    userValidator: UserValidator;
}

export interface RequiredUserClientDeps {
    apiClientFactory: ApiClientFactory;
    eventBus: EventBus;
    idGenerator: IdGenerator;
    storeFactory: StoreFactory;
    storeRegistry: StoreRegistry;
    validator: Validator;
}

export interface UserClientDeps extends ProvidedUserClientDeps, RequiredUserClientDeps {

}

export interface UserClientConfig {
    adminRoleId: UserRole['id'];
    passwordMinLength: number;
    passwordStrictPolicy: boolean;
}

export type UserClientProvider = Provider<UserClientDeps, UserClientConfig>;

export function createUserClientProvider(): UserClientProvider {
    return {

        registerDependencies: async ({register}, config) => {
            register('userApi', ({resolve}) => {
                return createUserClientApi({
                    apiClientFactory: resolve('apiClientFactory')
                });
            });
            register('userFactory', ({resolve}) => {
                return createUserFactory({
                    idGenerator: resolve('idGenerator')
                });
            });
            register('userHelper', () => {
                return createUserHelper({}, {
                    adminRoleId: config.adminRoleId
                });
            });
            register('userManager', ({resolve}) => {
                return createUserManager({
                    eventBus: resolve('eventBus'),
                    userApi: resolve('userApi'),
                    userStore: resolve('userStore')
                });
            });
            register('userRoleFactory', ({resolve}) => {
                return createUserRoleFactory({
                    idGenerator: resolve('idGenerator')
                });
            });
            register('userRoleValidator', ({resolve}) => {
                return createUserRoleValidator({
                    validator: resolve('validator')
                });
            });
            register('userStore', ({resolve}) => {
                return createUserStore({
                    storeFactory: resolve('storeFactory')
                });
            });
            register('userValidator', ({resolve}) => {
                return createUserValidator({
                    validator: resolve('validator')
                }, {
                    passwordMinLength: config.passwordMinLength,
                    passwordStrictPolicy: config.passwordStrictPolicy
                });
            });
        },

        registerServices: async ({resolve}) => {
            const storeRegistry = resolve('storeRegistry');
            const userStore = resolve('userStore');
            storeRegistry.registerStore(userStore);
        }

    };
}