import {PageRegistry} from '@webaker/app';
import {Provider} from '@webaker/package-deps';
import {UserRole} from '@webaker/package-user';
import {createPrivatePageOptions} from '../private-page-options';
import {createPublicPageOptions} from '../public-page-options';
import {createVirtualPageOptions} from '../virtual-page-options';

export interface ProvidedCommonPageClientDeps {

}

export interface RequiredCommonPageClientDeps {
    pageRegistry: PageRegistry;
}

export interface CommonPageClientDeps extends ProvidedCommonPageClientDeps, RequiredCommonPageClientDeps {

}

export interface CommonPageClientConfig {
    adminRoleId: UserRole['id'];
}

export type CommonPageServerProvider = Provider<CommonPageClientDeps, CommonPageClientConfig>;

export function createCommonPageClientProvider(): CommonPageServerProvider {

    return {

        registerServices: async ({resolve}) => {
            const pageRegistry = resolve('pageRegistry');
            const privatePageOptions = createPrivatePageOptions();
            const publicPageOptions = createPublicPageOptions();
            const virtualPageOptions = createVirtualPageOptions();
            pageRegistry.registerPage(privatePageOptions);
            pageRegistry.registerPage(publicPageOptions);
            pageRegistry.registerPage(virtualPageOptions);
        }

    };
}